import React from 'react';

const ResetModal = ({ show, onHide, setIsShareModalOpen }) => { // Updated props

    return (
        <div className="mdl backdrop-blur-[30px]" style={{ display: show ? 'flex' : 'none' }}>
            <div className="mdl-dia">
                <div className="mdl-tit">RELOAD ENTIRE MODEL?</div>
                <div className="mdl-alert">
                    <p>This action will erase current design. </p>
                    <p>You can <span className="share-safe-link" onClick={() => { onHide(false); setIsShareModalOpen(true) }}>download</span> it first.</p>
                </div>
                <div className='mdl-row'>
                    <button className="mdl-btn primary" type="button" onClick={() => {
                        onHide(true); // Also close the modal
                    }}>
                        APPLY
                    </button>
                    <button className="mdl-btn secondary" onClick={() => { onHide(false) }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default ResetModal;