import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import { black_icons } from '../../icons';

function BalloonSelection({ showModal, onClose, modelOptions, fabricOptions, selectedFabric, setFabric, setModel, selectedModel }) {
  const [selectedType, setSelectedType] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);

  const handleApply = () => {
    const selectedModel = modelOptions.items.find(item => item.name === selectedSize);

    if (selectedModel && selectedModel.data) {
      try {
        setModel(selectedModel);
        if (onClose) {
          onClose();
        }

      } catch (error) {
        console.error("Error fetching model data:", error);
      }
    } else {
      console.error('Model data URL not found for selected model');
    }
  };


  const DropdownIndicator = (
    props
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <black_icons.rollIcon />
      </components.DropdownIndicator>
    );
  };
  const Placeholder = (props) => (
    <components.Placeholder {...props}>
      Choose
    </components.Placeholder>
  );

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      width: '100%',
      minHeight: '40px',
      fontSize: '18px'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: 'var(---ffffff-white)',
      color: state.isSelected ? 'var(--kb-500-0084cc)' : 'var(--k-900-303030)',
      ':hover': {
        backgroundColor: 'var(---ffffff-white)',
        color: 'var(---e6ab00-hover-color)'
      },
    }),
    menu: (provided, state) => ({
      ...provided,
      fontSize: '18px',
    }),
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: 15,
    })
  };

  useEffect(() => {
    if (modelOptions && modelOptions.types.default) {
      setSelectedType(modelOptions.types.default);
    }
  }, []);

  return (

    <div className="mdl backdrop-blur-[30px]" style={{ display: showModal ? 'flex' : 'none' }}>
      <div className="mdl-dia">
        <form>
          <div className="form-group">
            <label className='mdl-lbl'>Balloon model:</label>
            <div className='rad-btns'>
              {modelOptions && modelOptions.types.items.map((item) => (
                <button
                  key={item.id}
                  className={`rad-btn ${selectedType === item.id ? 'checked' : ''}`}
                  id={"typeSelection-" + item.id}
                  name="typeSelection"
                  value={item.id}
                  onClick={(e) => {
                    e.preventDefault();
                    (item.id !== selectedType) && setSelectedSize("Choose");
                    setSelectedType(item.id);
                    setSelectedSize(modelOptions.items.filter(m => m.type === item.id)[0].name);
                  }}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
          <div className='mdl-row'>
            {selectedType && <div className="form-group">
              <label className='mdl-lbl' htmlFor="sizeSelection">Size:</label>
              <Select
                inputId="sizeSelection"
                inputName="sizeSelection"
                value={selectedSize ? { value: selectedSize, label: modelOptions.items.find(item => item.name === selectedSize).name } : null}
                onChange={(selectedOption) => setSelectedSize(selectedOption.value)}
                options={modelOptions.items.filter(item => item.type === selectedType).map((item) => (
                  {
                    value: item.name,
                    label: item.name
                  }
                ))}
                className="basic-multi-select"
                classNamePrefix="select"
                components={{ DropdownIndicator, Placeholder }}
                styles={customStyles}
                menuPortalTarget={document.querySelector("body")}
              />

            </div>}
            {fabricOptions && <div className="form-group">
              <label className='mdl-lbl' htmlFor="fabricSelection">Fabric:</label>
              <Select
                inputId="fabricSelection"
                inputName="fabricSelection"
                onChange={(selectedOption) => {
                  setFabric(selectedOption.value);
                }}
                value={selectedFabric ? { value: selectedFabric, label: selectedFabric.name } : null}
                options={fabricOptions.items.map((item) => (
                  {
                    value: item,
                    label: item.name
                  }))
                }
                className="basic-multi-select"
                classNamePrefix="select"
                components={{ DropdownIndicator, Placeholder }}
                styles={customStyles}
                menuPortalTarget={document.querySelector("body")}
              />
            </div>}
          </div>
          <p className="mdl-alert">Any change of specification will erase current design!</p>
          <div className='mdl-row'>
            <button className="mdl-btn primary" type="button" onClick={handleApply} disabled={!selectedType || !selectedFabric || !selectedSize}>APPLY</button>
            <button className="mdl-btn secondary" type="button" onClick={onClose}>CANCEL</button>
          </div>
        </form>

      </div>
    </div>
  );
}

export default BalloonSelection;