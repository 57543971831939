import React, { useState, useEffect } from 'react';
import { icons, black_icons, white_icons } from '../../icons';
import BalloonSelection from '../BalloonSelection/BalloonSelection';
import ResetModal from '../ResetModal/ResetModal';
import ShareModal from '../ShareModal/ShareModal';
import SharingDoneModal from '../SharingDoneModal/SharingDoneModal';
import FloatingToolBar from '../FloatingToolBar/FloatingToolBar';

function TopBar({
  modelOptions, fabricOptions, selectedModel,
  onSetModel, selectedFabric, onSetFabric,
  onReset, canvasState, settings, onUndo, onRedo,
  history, redoStack, viewMode, setViewMode,
  scenes, selectedScene, setSelectedScene,
  designerRef, canvasRef }) {

  const [isBallonSelectionOpen, setIsBallonSelectionOpen] = useState(selectedModel === null);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isSharingDoneModalOpen, setIsSharingDoneModalOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1375);
  const [isReallySmallScreen, setIsReallySmallScreen] = useState(window.innerWidth < 1080);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1375);
      setIsReallySmallScreen(window.innerWidth < 1080);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const handleCloseResetModal = (doReset) => {
    if (doReset) {
      handleReset()
    };
    setIsResetModalOpen(false);
  };

  const handleReset = () => {
    onReset();
    handleCloseResetModal();
  };


  return (
    <nav className="flex justify-between fixed top-0 left-0 w-full p-3 z-10 gap-4">
      <div className="flex-1 flex flex-row justify-start gap-4">
        <a href="https://kubicekballoons.com" className="nav-btns nav-logo" target="_blank" rel="noopener noreferrer">
          <icons.companyLogo alt="Company Logo" />
        </a>
        <button className="nav-btns mod-sel-btn"
          onClick={() => setIsBallonSelectionOpen(true)}>
          {isReallySmallScreen ? (selectedModel ? selectedModel.name : "SELECT MODEL") : (selectedModel && selectedFabric ? (
            <>
              {selectedModel.name}
              <span style={{ fontSize: 'var(--unnamed-font-size-8)', height: '100%' }}>{" / " + selectedFabric.name}</span>
            </>
          ) : "SELECT MODEL")}
          <black_icons.rollIcon alt="" style={{ paddingLeft: '8px' }} />
        </button>

        <button className="nav-btns  res-btn"
          onClick={() => setIsResetModalOpen(true)}>
          <black_icons.resetModelIkonB alt="Reset Model" />
          {!isSmallScreen && <span className="btn-text">NEW ARTBOARD</span>}
        </button>
      </div>
      <div className='flex-0 flex flex-row justify-center gap-2 '>
        <button className="his-btn" onClick={onUndo} disabled={history.length === 0}>
          <black_icons.undoIconB alt="Undo" />
        </button>
        <button className="his-btn" onClick={onRedo} disabled={redoStack.length === 0}>
          <black_icons.redoIconB alt="Redo" />
        </button>
      </div>
      <div className='flex-1 flex flex-row justify-end'>
        <button className="nav-btns share-btn"
          onClick={() => setIsShareModalOpen(true)}>
          {!isReallySmallScreen && <div className="btn-text">Download</div>}
          <white_icons.shareAction alt="Share action" />
        </button>
      </div>
      <FloatingToolBar
        viewMode={viewMode}
        onViewModeChange={(mode) => { setViewMode(mode) }}
        scenes={scenes}
        selectedScene={selectedScene}
        setSelectedScene={setSelectedScene}
      />
      <BalloonSelection
        showModal={isBallonSelectionOpen}
        onClose={() => setIsBallonSelectionOpen(false)}
        modelOptions={modelOptions}
        fabricOptions={fabricOptions}
        selectedFabric={selectedFabric}
        setFabric={onSetFabric}
        setModel={onSetModel}
        selectedModel={selectedModel}
      />
      <ResetModal show={isResetModalOpen} onHide={handleCloseResetModal} setIsShareModalOpen={setIsShareModalOpen} />
      <SharingDoneModal show={isSharingDoneModalOpen} onHide={() => setIsSharingDoneModalOpen(false)} />
      <ShareModal show={isShareModalOpen} onHide={(sharingDone) => { if (sharingDone) { setIsSharingDoneModalOpen(true) }; setIsShareModalOpen(false) }} selectedModel={selectedModel} selectedFabric={selectedFabric} canvasState={canvasState} settings={settings} designerRef={designerRef} canvasRef={canvasRef} />
    </nav>
  );
}

export default TopBar;