import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { black_icons } from '../../icons';

const ShareModal = ({ show, onHide, onSubmit, selectedModel, selectedFabric, canvasState, settings, designerRef, canvasRef }) => {

    const [selectedCountry, setSelectedCountry] = useState(null);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [priceRequest, setPriceRequest] = useState(false);
    const [countryOptions, setCountryOptions] = useState([]);
    const [designName, setDesignName] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {

        const default_option = settings.countries.default;

        const options = Object.entries(settings.countries.items).map(([key, value]) => {
            if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
                return {
                    label: key,
                    options: Object.entries(value).map(([key, value]) => {
                        if (key === default_option) {
                            setSelectedCountry(key);
                        }
                        return {
                            value: key,
                            label: value,
                            type: 'optgroup'
                        }
                    })
                }
            }
            else {
                if (key === default_option) {
                    setSelectedCountry(key);
                }
                return {
                    value: key,
                    label: value,
                    type: 'option'
                };
            }

        });
        setCountryOptions(options);
    }, []);

    const handleCountryChange = (selectedOption) => {
        if (selectedOption) {
            setSelectedCountry(selectedOption.value);
        } else {
            setSelectedCountry(null);
        }
    };

    const handleDownloadPictures = (() => {
        if (designerRef.current) {
            designerRef.current.downloadPictures(designName || selectedModel.name + " - " + selectedFabric.name, [0]);
        }
    })

    const handlePhoneChange = (event) => {
        setPhoneNumber(event.target.value);
    };

    const DropdownIndicator = (
        props
    ) => {
        return (
            <components.DropdownIndicator {...props}>
                <black_icons.rollIcon />
            </components.DropdownIndicator>
        );
    };
    const Placeholder = (props) => (
        <components.Placeholder {...props}>
            Choose
        </components.Placeholder>
    );

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            width: '100%',
            minHeight: '40px',
        }),
        option: (provided, state) => {
            return {
                ...provided,
                backgroundColor: 'var(---ffffff-white)',
                color: state.isSelected ? 'var(--kb-500-0084cc)' : 'var(--k-900-303030)',
                ':hover': {
                    backgroundColor: 'var(---ffffff-white)',
                    color: 'var(---e6ab00-hover-color)'
                },
                paddingLeft: state.data.type === 'option' ? '10px' : '15px',
            }
        },
        menuPortal: (provided, state) => ({
            ...provided,
            zIndex: 15,
        }),
        groupHeading: (provided) => ({
            ...provided,
            paddingLeft: '10px',
            fontSize: '16px',
            fontWeight: 'bold',
        }),
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const formData = {
            balloonName: event.target.elements.balloonName.value,
            name: event.target.elements.name.value,
            email: event.target.elements.email.value,
            country: selectedCountry,
            phone: event.target.elements.phone.value,
            priceRequest: priceRequest,
        };

        let hasEmptyFields = false;
        for (const field in formData) {
            if (field === 'priceRequest' || field === 'phone') continue;
            if (!formData[field]) {
                hasEmptyFields = true;
                break;
            }
        }

        if (hasEmptyFields) {
            alert('Please fill in all required fields.');
        } else {
            const hexToIdMap = {};
            selectedFabric.colors.items.forEach(color => {
                hexToIdMap[color.hex] = color.id;
            });

            const nomexHexToIdMap = {};
            selectedFabric.nomex.items.forEach(color => {
                nomexHexToIdMap[color.hex] = color.id;
            });

            let nomexColor;

            const modifiedEnvelopeGrid = canvasState.envelopeGrid.map((row, idx) => {
                if (idx === 0) {
                    nomexColor = nomexHexToIdMap[row[0]] || row[0];
                    return row.map(hexValue => nomexHexToIdMap[hexValue] || hexValue)
                } else {
                    return row.map(hexValue => hexToIdMap[hexValue] || hexValue)
                }
            }
            );

            const pictures = designerRef.current.getPictures([0, 1, 2, 3, 4, 5, 6]).catch(error => {
                console.error("Error taking pictures:", error);
            });

            const envelope2D = canvasRef.current.getEnvelope2D();

            const data = {
                formData: formData,
                canvasState: {
                    ...canvasState,
                    envelopeGrid: modifiedEnvelopeGrid,
                    nomexColor: nomexColor
                },
                selectedModelID: selectedModel.id,
                selectedFabricID: selectedFabric.id,
                images: await pictures,
                envelope2D: await envelope2D
            };

            const formDataToSend = new FormData();
            for (const key in data) {
                formDataToSend.append(key, ['selectedModelID', 'selectedFabricID'].includes(key) ? data[key] : JSON.stringify(data[key]));
            }

            fetch(' https://kubicekballoons.com/en/designer-api/design/', {
                method: 'POST',
                body: formDataToSend
            })
                .then(response => response.json())
                .then(data => {
                    const url = new URL(window.location);
                    url.searchParams.set('id', data.id);
                    window.history.pushState({}, '', url);
                    onHide(true);
                    setIsSubmitting(false);
                    setDesignName('');
                    setPhoneNumber('');
                    setPriceRequest(true);
                    setName('');
                    setEmail('');
                    setSelectedCountry(null);
                })
                .catch(error => console.error('Error:', error));
        }
    };

    return (
        <div className="mdl backdrop-blur-[30px]" style={{ display: show ? 'flex' : 'none' }}>
            <div className="mdl-dia">
                <form onSubmit={handleSubmit}>
                    <div className='mdl-row'>
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicBalloonName">NAME YOUR DESIGN</label>
                            <input type="text" className="form-control" id="formBasicBalloonName" placeholder={selectedModel && selectedFabric.name ? selectedModel.name + " - " + selectedFabric.name : "SELECT MODEL"} required name="balloonName" autoComplete='off' value={designName} onChange={(e) => setDesignName(e.target.value)} />
                        </div>
                        <button type="button" className="mdl-btn secondary align-end" onClick={() => { handleDownloadPictures(); onHide(false) }}>SIMPLE DOWNLOAD (.JPG)</button>
                    </div>
                    <div className='mdl-info'>
                        <div className='mdl-text'>GET COMPLETE BALLOON VISUAL</div>
                        <div className='mdl-sub-text'>Complete PDF hi-res layout with 6 views, colour palette and live link to editable model.</div>
                    </div>
                    <div className="mdl-row">
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicName">YOUR NAME</label>
                            <input type="text" className="form-control" id="formBasicName" placeholder="Name" required name="name" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicEmail">E-MAIL</label>
                            <input type="email" className="form-control" id="formBasicEmail" placeholder="example@dot.com" required name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="mdl-row">
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="country">COUNTRY</label>
                            <Select
                                options={countryOptions}
                                isClearable={true}
                                onChange={handleCountryChange}
                                value={(() => {
                                    const group = countryOptions.find(o => o.options?.some(sub => sub.value === selectedCountry));
                                    if (group) {
                                        const selectedSubOption = group.options.find(sub => sub.value === selectedCountry);
                                        return {
                                            label: group.label + ' - ' + selectedSubOption.label,
                                            value: selectedSubOption.value,
                                            type: 'optgroup'
                                        };
                                    } else if (countryOptions.some(o => o.value === selectedCountry)) {
                                        const selectedOption = countryOptions.find(o => o.value === selectedCountry);
                                        return {
                                            label: selectedOption.label,
                                            value: selectedOption.value,
                                            type: 'option'
                                        };
                                    } else {
                                        return null;
                                    }
                                })()}
                                components={{ DropdownIndicator, Placeholder }}
                                styles={customStyles}
                                className="basic-multi-select"
                                classNamePrefix="select"
                                inputId="country"
                                inputName="country"
                                required name='country'
                                menuPortalTarget={document.querySelector("body")}
                            />
                        </div>
                        <div className="form-group">
                            <label className="mdl-lbl" htmlFor="formBasicPhone">Phone Number (Optional)</label>
                            <input type="tel" className="form-control" id="formBasicPhone" placeholder={`Enter Phone Number`}
                                value={phoneNumber}
                                name="phone"
                                onChange={handlePhoneChange} />
                        </div>
                    </div>
                    <div className="mdl-sub-text">
                        <input type="checkbox" disabled={isSubmitting} checked={priceRequest} onChange={() => setPriceRequest(!priceRequest)} /> Request price
                    </div>
                    <div className="mdl-row">
                        <button type="submit" disabled={isSubmitting}
                            className={`mdl-btn primary ${isSubmitting ? 'submitting' : ''}`}
                        >
                            {isSubmitting ? <p>SUBMITTING...</p> : "Get Design by Email"}
                        </button>
                        <button type="button" disabled={isSubmitting} className="mdl-btn secondary" data-bs-dismiss="modal" onClick={() => onHide(false)}>
                            CANCEL
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
};

export default ShareModal;
