import React, { useRef, useEffect, useState, useCallback, useImperativeHandle } from 'react';
import { fabric } from 'fabric';
import { v4 as uuidv4 } from 'uuid';

function Canvas({ svgContent, svgDimensions,
  selectedTool, setSelectedTool, selectedColor, selectedNomexColor,
  canvasState, setCanvasState, setEnvelopeDimension,
  defaultColor, defaultNomexColor, history, setHistory,
  redoStack, setRedoStack, viewMode, paraventSize, setHighLightColor }, ref) {
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const [canvas, setCanvas] = useState(null);

  const historyLimit = 100;

  const [iEnvelopeGrid, setIEnvelopeGrid] = useState([]);
  const [iParaventGrid, setIParaventGrid] = useState(null);
  const [iImageGroup, setIImageGroup] = useState([]);
  const [iTextGroup, setITextGroup] = useState([]);
  const [iParaventLogo, setIParaventLogo] = useState(null);
  const [envelopeClipPath, setEnvelopeClipPath] = useState(null);
  const importantParameters = ['id', 'type', 'src', 'text', 'fontFamily', 'fontSize', 'fontWeight', 'fontStyle', 'fill', 'left', 'top', 'angle', 'scaleX', 'scaleY', 'centerPointX', 'lineHeight']


  useImperativeHandle(ref, () => ({
    addImage: (imageDataUrl) => {
      addImage({ src: imageDataUrl }, false);
    },
    addLogo: (imageDataUrl) => {
      addImage({ src: imageDataUrl }, true);
    },
    addText: (curentTextStyle) => {
      addText(curentTextStyle)
    },
    updateText: (curentTextStyle) => {
      if (canvas) {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
          modifyObject(activeObject, curentTextStyle, true);
          activeObject.dirty = true;
        }
      }
    },
    bringForward: () => {
      if (canvas) {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          canvas.bringForward(activeObject);
        }
        updateCanvasState(getAllImportantInformations(activeObject), true);
      }
    },
    bringBackward: () => {
      if (canvas) {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          const canvasObjects = canvas.getObjects();
          const lastRectIndex = canvasObjects.findLastIndex(obj => obj.type === 'rect' && obj !== activeObject);
          const activeObjectIndex = canvas.getObjects().indexOf(activeObject);

          if (activeObjectIndex > lastRectIndex + 1) {
            canvas.sendBackwards(activeObject);
            updateCanvasState(getAllImportantInformations(activeObject), true);

          }
          // canvas.sendBackwards(activeObject);
        }
      }
    },
    removeObject: () => {
      const activeObject = canvas.getActiveObject();
      removeObject(activeObject);
    },
    removeLogo: () => {
      if (canvas) {
        removeObject(iParaventLogo);
      }
    },
    cloneObject: () => {
      if (canvas) {
        const activeObject = canvas.getActiveObject();
        if (activeObject) {
          const params = getAllImportantInformations(activeObject);
          params.left = activeObject.left + 20;
          params.id = uuidv4();
          if (activeObject && (activeObject.type === 'image' || activeObject.type === 'i-text')) {
            if (activeObject.type === 'image') {
              setIImageGroup((prevImageGroup) => [...prevImageGroup, activeObject]);
              addImage(params, false);
            }
            else {
              setITextGroup((prevTextGroup) => [...prevTextGroup, activeObject]);
              addText(params);
            }
          }
        }

      }
    },
    undo: () => {
      if (history.length > 0) {
        const lastChanges = history.at(-1);
        applyChangesToCanvas(lastChanges, "undo")
        setRedoStack((prevRedoStack) => [...prevRedoStack, lastChanges]);
        setHistory((prevHistory) => {
          return prevHistory.slice(0, -1);
        });
      }
    },
    redo: () => {
      if (redoStack.length > 0) {
        const lastChanges = redoStack.at(-1);
        applyChangesToCanvas(lastChanges, "redo")
        setRedoStack((prevRedoStack) => [...prevRedoStack.slice(0, -1)])
        setHistory((prevHistory) => {
          const newHistory = [...prevHistory, lastChanges];
          return newHistory.slice(newHistory.length - historyLimit);
        });
      }
    },
    getEnvelope2D: async () => {
      if (canvas) {
        const originalTool = selectedTool;

        try {
          setSelectedTool("Graphic");
          await new Promise(resolve => setTimeout(resolve, 0));

          const base64Image = canvas.toDataURL('image/jpeg');
          return base64Image;
        } finally {
          setSelectedTool(originalTool);
          await new Promise(resolve => setTimeout(resolve, 0));
        }
      }
      return null;
    }
  }));

  const getAllImportantInformations = (object) => {
    const objectRepr = {}
    importantParameters.forEach(param => {
      if (object[param] !== undefined) {
        objectRepr[param] = object[param]
      }
    })
    if (object.type === "image") {
      objectRepr['src'] = object.getSrc()
    }
    objectRepr['centerPointX'] = object.getCenterPoint().x
    objectRepr['centerPointY'] = object.getCenterPoint().y
    return objectRepr;
  }

  const compareObjects = (object1, object2) => {
    const o = { id: object1.id };
    const n = { id: object2.id };
    importantParameters.forEach(param => {
      if (object1[param] !== object2[param]) {
        o[param] = object1[param]
        n[param] = object2[param]
      }
    })
    return { old: o, new: n }
  }

  const updateCanvasState = (object, history = true) => {

    let historyEntry = {};

    setCanvasState((prevCanvasState) => {
      if (!prevCanvasState) return;

      let updatedState = { ...prevCanvasState };

      if (Object.keys(object).length === 2 && object.hasOwnProperty('id') && object.hasOwnProperty('type')) {
        historyEntry.path = object.type === "image" ?
          (object.id === "paravent-logo" ? "paraventLogo" : "images") :
          "texts";

        if (object.type === "image") {
          if (object.id === "paravent-logo") {
            historyEntry.old = prevCanvasState.paraventLogo;
            updatedState.paraventLogo = null;
          } else {
            const existingImageIndex = prevCanvasState.images.findIndex(element => element.id === object.id);
            if (existingImageIndex !== -1) {
              historyEntry.old = prevCanvasState.images[existingImageIndex];
              updatedState.images = prevCanvasState.images.filter((_, index) => index !== existingImageIndex);
            }
          }
        } else {
          const existingTextIndex = prevCanvasState.texts.findIndex(element => element.id === object.id);
          if (existingTextIndex !== -1) {
            historyEntry.old = prevCanvasState.texts[existingTextIndex];
            updatedState.texts = prevCanvasState.texts.filter((_, index) => index !== existingTextIndex);
          }
        }

        historyEntry.new = null;
      } else {

        if (object.type === "image") {
          if (object.id === "paravent-logo") {
            historyEntry.path = "paraventLogo";

            if (prevCanvasState.paraventLogo) {
              historyEntry = { ...historyEntry, ...compareObjects(prevCanvasState.paraventLogo, object) };
            } else {
              historyEntry.id = object.id;
              historyEntry.old = null;
              historyEntry.new = object;
            }

            updatedState.paraventLogo = object;
          } else {
            const existingImageIndex = prevCanvasState.images.findIndex(element => element.id === object.id);

            if (existingImageIndex !== -1) {
              historyEntry.path = "images";
              historyEntry = { ...historyEntry, ...compareObjects(prevCanvasState.images[existingImageIndex], object) };

              updatedState.images = prevCanvasState.images.map((element, index) =>
                index === existingImageIndex ? object : element
              );
            } else {
              historyEntry.path = "images";
              historyEntry.id = object.id;
              historyEntry.old = null;
              historyEntry.new = object;

              updatedState.images = [...prevCanvasState.images, object];
            }
          }
        } else {
          const existingTextIndex = prevCanvasState.texts.findIndex(element => element.id === object.id);

          if (existingTextIndex !== -1) {
            historyEntry.path = "texts";
            historyEntry = { ...historyEntry, ...compareObjects(prevCanvasState.texts[existingTextIndex], object) };

            updatedState.texts = prevCanvasState.texts.map((element, index) =>
              index === existingTextIndex ? object : element
            );
          } else {
            historyEntry.path = "texts";
            historyEntry.id = object.id;
            historyEntry.old = null;
            historyEntry.new = object;

            updatedState.texts = [...prevCanvasState.texts, object];
          }
        }
      }
      if (Object.keys(historyEntry).length > 0 && history) {
        setHistory((prevHistory) => {
          const newHistory = [...prevHistory, [historyEntry]];
          return newHistory.slice(newHistory.length - historyLimit);
        });
      }
      if (canvas) {
        const canvasObjects = canvas.getObjects();
        const objectIndexMap = new Map();
        canvasObjects.forEach((obj, index) => {
          objectIndexMap.set(obj.id, index);
        });
        updatedState.images.sort((a, b) => objectIndexMap.get(a.id) - objectIndexMap.get(b.id))
        updatedState.texts.sort((a, b) => objectIndexMap.get(a.id) - objectIndexMap.get(b.id))
      }
      return updatedState;
    });
  };

  const applyChangesToCanvas = (changes, action) => {
    changes.forEach((change) => {
      if (change.path === "envelopeGrid") {
        iEnvelopeGrid[change.row][change.col].getObjects()[0].set('fill', action === "undo" ? change.old : change.new)
        iEnvelopeGrid[change.row][change.col].getObjects()[0].dirty = true;
      }
      if (change.path === "paraventLogo") {
        if (action === "undo") {
          if (change.new && !change.old) {
            removeObject(iParaventLogo, false)
          } else if (change.old.id === change.new.id) {
            modifyObject(iParaventLogo, change.old, false)
          } else {
            addImage(change.old, true, false);
          }
        } else {
          if (!change.new && change.old) {
            removeObject(iParaventLogo, false)
          } else if (change.new && !change.old) {
            addImage(change.new, true, false);
          } else {
            modifyObject(iParaventLogo, change.new, false)
          }
        }
      }
      if (change.path === "images") {
        if (action === "undo") {
          if (change.new && !change.old) {
            removeObject(iImageGroup.find(image => image.id === change.new.id), false)
          } else if (!change.new && change.old) {
            addImage(change.old, false, false);
          } else {
            modifyObject(iImageGroup.find(image => image.id === change.new.id), change.old, false)
          }
        }
        else {
          if (!change.new && change.old) {
            removeObject(iImageGroup.find(image => image.id === change.old.id), false)
          } else if (change.new && !change.old) {
            addImage(change.new, false, false);
          } else {
            modifyObject(iImageGroup.find(image => image.id === change.old.id), change.new, false)
          }
        }
      }
      if (change.path === "texts") {
        if (action === "undo") {
          if (change.new && !change.old) {
            removeObject(iTextGroup.find(text => text.id === change.new.id), false)
          } else if (change.old.id === change.new.id) {
            modifyObject(iTextGroup.find(text => text.id === change.new.id), change.old, false)
          } else {
            addText(change.old, false)
          }
        }
        else {
          if (!change.new && change.old) {
            removeObject(iTextGroup.find(text => text.id === change.old.id), false)
          } else if (change.new && !change.old) {
            addText(change.new, false);
          } else {
            modifyObject(iTextGroup.find(text => text.id === change.old.id), change.new, false)
          }
        }

      }
    })
    setCanvasState((prevCanvasState) => {
      changes.forEach((change) => {
        if (change.path === "envelopeGrid") {
          prevCanvasState.envelopeGrid[change.row][change.col] = action === "undo" ? change.old : change.new;
        }
      })
      return {
        ...prevCanvasState
      }
    })
    canvas.requestRenderAll()
  }


  const addImage = (imageData, paravent, history = true, stateUpdate = true, clipPath = null) => {
    if (canvas) {
      fabric.Image.fromURL(imageData.src, (img) => {
        let scaleX = 1;
        let scaleY = 1;

        if (paravent && iParaventGrid) clipPath = iParaventGrid;
        else if (!paravent && envelopeClipPath) clipPath = envelopeClipPath;

        if (img.width > svgDimensions.width) {
          scaleX = paravent ? clipPath.radius / img.width : clipPath.width / img.width;
        }
        if (img.height > svgDimensions.height) {
          scaleY = paravent ? clipPath.radius / img.width : clipPath.height / img.height;
        }

        const scale = Math.min(scaleX, scaleY);

        const clipPathCenterX = paravent
          ? clipPath.left + clipPath.radius
          : clipPath.left + clipPath.width / 2;

        const clipPathCenterY = paravent
          ? clipPath.top + clipPath.radius
          : clipPath.top + clipPath.height / 2;

        img.set({
          id: paravent ? "paravent-logo" : (imageData.id || uuidv4()),
          left: imageData.left || clipPathCenterX - (img.width * scale) / 2,
          top: imageData.top || clipPathCenterY - (img.height * scale) / 2,
          angle: imageData.angle || 0,
          scaleX: imageData.scaleX || scale,
          scaleY: imageData.scaleY || scale,
          selectable: (selectedTool === "Graphic" && !paravent) || (selectedTool === "Paravent" && paravent),
          evented: (selectedTool === "Graphic" && !paravent) || (selectedTool === "Paravent" && paravent),
          hoverCursor: "grab",
          visible: (selectedTool === "Graphic" && !paravent) || (selectedTool === "Paravent" && paravent)
        })
        if (imageData.width) {
          img.set('width', ImageData.width);
        }
        if (imageData.height) {
          img.set('height', ImageData.height);
        }
        paravent ?
          setIParaventLogo((prevParaventLogo) => { canvas.remove(prevParaventLogo); return img })
          : setIImageGroup((prevImageGroup) => [...prevImageGroup, img]);
        img.clipPath = clipPath;
        canvas.add(img);
        if (stateUpdate) { updateCanvasState(getAllImportantInformations(img), history) }
      })
    }
  }

  const addText = (textData, history = true) => {
    if (canvas) {
      const canvasCenterX = svgDimensions.width / 2;
      const canvasCenterY = svgDimensions.height / 2;

      const newText = new fabric.IText(textData.text || "IT’S TIME TO FLY", {
        id: textData.id || uuidv4(),
        fontFamily: textData.fontFamily,
        fontWeight: textData.fontWeight,
        fontStyle: textData.fontStyle,
        fill: textData.fill,
        selectable: selectedTool === "Text",
        evented: selectedTool === "Text",
        visible: selectedTool === "Text",
        angle: textData.angle || 0,
        scaleX: textData.scaleX || 1,
        scaleY: textData.scaleY || 1,
        top: textData.top || 0,
        left: textData.left || 0,
        padding: 0,
        lineHeight: textData.lineHeight || 1,
      })
      newText.set('left', textData.left || canvasCenterX - newText.width / 2);
      newText.set('top', textData.top || canvasCenterY - newText.height / 2)

      newText.clipPath = envelopeClipPath;
      canvas.add(newText)
      updateCanvasState(getAllImportantInformations(newText), history)
      setITextGroup((prevTextGroup) => [...prevTextGroup, newText]);
    }
  }

  const modifyObject = (object, changes, history = true) => {
    object.set(changes);
    object.dirty = true;
    updateCanvasState(getAllImportantInformations(object), history)
    canvas.requestRenderAll();
  }

  const removeObject = (object, history = true) => {
    if (canvas) {
      const params = getAllImportantInformations(object)
      if (object && (object.type === 'image' || object.type === 'i-text')) {
        if (object.type === 'image') {
          if (object.id === "paravent-logo") {
            setIParaventLogo(null)
          } else {
            setIImageGroup((prevImageGroup) => {
              prevImageGroup.splice(iImageGroup.indexOf(object), 1)
              return prevImageGroup;
            });
          }
        }
        else {
          setITextGroup((prevTextGroup) => prevTextGroup.splice(iTextGroup.indexOf(object), 1));
        }
        updateCanvasState({ id: params.id, type: params.type }, history);
        canvas.remove(object);
      }
    }
  }

  const getCoordinatesFromId = (id) => {
    const parts = id.split('-');
    if (parts.length === 2) {
      return { row: parseInt(parts[0]), col: parseInt(parts[1]) - 1 };
    }
    return null;
  }

  const createGradient = (rectWidth, rectHeight) => {
    return new fabric.Gradient({
      type: 'linear',
      coords: {
        x1: 0,
        y1: 0,
        x2: 0,
        y2: rectHeight
      },
      colorStops: [
        { offset: 0, color: 'white' },
        { offset: 1, color: 'black' }
      ]
    });
  }

  const selectAllRectangles = () => {
    if (canvas) {
      return iEnvelopeGrid.slice(1).flat();
    } else {
      return [];
    }
  };

  const selectEntireRow = (rowNumber) => {
    if (canvas && rowNumber >= 1) {
      return iEnvelopeGrid[rowNumber];
    } else {
      return [];
    }
  };

  const selectEntireColumn = (columnNumber) => {
    if (canvas) {
      return iEnvelopeGrid.slice(1, -1).map((row) => row[columnNumber]);
    } else {
      return [];
    }
  };

  const selectDiagonalLine = (clickedRow, clickedCol, direction) => {
    if (canvas) {
      const rows = iEnvelopeGrid.length;
      const cols = iEnvelopeGrid[0].length;
      const selectedElements = [];

      for (let row = 1; row < rows - 1; row++) {
        for (let col = 0; col < cols; col++) {
          if (direction === 'up') {
            if (Math.abs((row - col) - (clickedRow - clickedCol)) % cols === 0) {
              selectedElements.push(iEnvelopeGrid[row][col]);
            }
          } else if (direction === 'down') {
            if (Math.abs((row + col) - (clickedRow + clickedCol)) % cols === 0) {
              selectedElements.push(iEnvelopeGrid[row][col]);
            }
          }
        }
      }

      return selectedElements;
    } else {
      return [];
    }
  };

  const selectSameColor = (target) => {
    if (canvas) {
      const allRects = selectAllRectangles();
      const selectedRects = [];
      const targetColor = target.getObjects()[0].fill;
      allRects.forEach(rect => {
        if (rect.getObjects()[0].fill === targetColor) {
          selectedRects.push(rect);
        }
      });
      return selectedRects;
    } else {
      return [];
    }
  }

  const getTargetRectangles = (target) => {

    const { row, col } = getCoordinatesFromId(target.id);
    switch (selectedTool) {
      case "Panel":
        if (row === iEnvelopeGrid.length - 1) {
          return selectEntireRow(row);
        }
        return [target]
      case "Row":
        return selectEntireRow(row)
      case "Gore":
        if (row === iEnvelopeGrid.length - 1) {
          return [];
        }
        return selectEntireColumn(col)
      case "Envelope":
        return selectAllRectangles()
      case "SpiralUp":
        if (row === iEnvelopeGrid.length - 1) {
          return [];
        }
        return selectDiagonalLine(row, col, "up")
      case "Spiral":
        if (row === iEnvelopeGrid.length - 1) {
          return [];
        }
        return selectDiagonalLine(row, col, "down")
      case "Swap":
        return selectSameColor(target)
      default:
        return [];
    }

  }

  const clearCanvas = () => {
    if (canvas) {
      canvas.getObjects().forEach(object => {
        canvas.remove(object);
      });
      canvas.requestRenderAll();

      setIEnvelopeGrid([]);
      setIParaventGrid(null);
      setIImageGroup([]);
      setIParaventLogo(null);
      setITextGroup([]);
      canvas.off('object:modified')
      canvas.off('mouse:out')

    }
  };

  const handleResize = () => {
    fitResponsiveCanvas(canvasRef.current, svgDimensions);
  };

  useEffect(() => {
    const canvas = new fabric.Canvas(canvasContainerRef.current, {
      width: svgDimensions.width, height: svgDimensions.height,
      backgroundColor: "#303030",
    });

    setCanvas(canvas)
    canvasRef.current = canvas;
    window.addEventListener('resize', handleResize, { passive: true })
    fitResponsiveCanvas(canvasRef.current, svgDimensions)
    canvas.requestRenderAll();

    return () => {
      window.removeEventListener('resize', handleResize);
      canvas.dispose();
    }
  }, []);

  useEffect(() => {
    if (canvas) {
      window.addEventListener('resize', handleResize, { passive: true })
      handleResize()
    }
  }, [viewMode])

  const fitResponsiveCanvas = (canvas, svgDimensions) => {
    const viewportWidth = window.innerWidth;
    const aspectRatio = svgDimensions.width / svgDimensions.height;
    let newHeight = viewportWidth / aspectRatio;
    let newWidth = newHeight * aspectRatio;
    if (Math.floor(newWidth) > viewportWidth) {
      newWidth = viewportWidth;
      newHeight = newWidth / aspectRatio;
    }
    if (Math.floor(newHeight) > (viewMode === "Grid" ? window.innerHeight - 135 : window.innerHeight * 0.35)) {
      newHeight = viewMode === "Grid" ? window.innerHeight - 135 : window.innerHeight * 0.35;
      newWidth = newHeight * aspectRatio;
      if (Math.floor(newWidth) > window.innerWidth) {
        newWidth = window.innerWidth;
        newHeight = newWidth / aspectRatio;
      }
    }
    if (canvas.width !== newWidth || canvas.height !== newHeight) {
      canvas.setDimensions({ width: newWidth, height: newHeight });
      canvas.setZoom(newWidth / svgDimensions.width);
    }
  }

  useEffect(() => {
    if (canvas && iEnvelopeGrid) {
      let lastHoveredGroup = null;
      selectAllRectangles().forEach((rectGroup) => {
        rectGroup.off('mousedown')
        rectGroup.off('mouseover')
        rectGroup.off('mouseout')
        rectGroup.on('mousedown', () => {
          const changes = [];
          getTargetRectangles(rectGroup).forEach((targetGroup) => {
            const { row, col } = getCoordinatesFromId(targetGroup.id);
            if (canvasState.envelopeGrid[row][col] !== selectedColor.hex) {
              const targetObject = targetGroup.getObjects()[0]
              changes.push({ path: 'envelopeGrid', row: row, col: col, old: targetObject.fill, new: selectedColor.hex })
              targetGroup.getObjects()[0].set('fill', selectedColor.hex);
              targetGroup.getObjects()[0].dirty = true
            }
          });
          setHistory((prevHistory) => {
            const newHistory = [...prevHistory, changes];
            return newHistory.slice(newHistory.length - historyLimit);
          });
          setRedoStack([]);
          setCanvasState((prevCanvasState) => {
            changes.forEach((change) => {
              prevCanvasState.envelopeGrid[change.row][change.col] = change.new;
            })
            return {
              ...prevCanvasState
            }
          })
          canvas.requestRenderAll()
          setHighLightColor(rectGroup.getObjects()[0].fill);
        })
        rectGroup.on('mouseover', (e) => {
          const targetRectangles = getTargetRectangles(rectGroup)
          targetRectangles.forEach((targetGroup) => {
            targetGroup.getObjects()[1].set('visible', true);
            targetGroup.getObjects()[1].dirty = true
          });
          canvas.requestRenderAll()
          lastHoveredGroup = targetRectangles;
          setHighLightColor(rectGroup.getObjects()[0].fill);
        })
        rectGroup.on('mouseout', () => {
          const targetRectangles = getTargetRectangles(rectGroup)
          targetRectangles.forEach((targetGroup) => {
            targetGroup.getObjects()[1].set('visible', false);
            targetGroup.getObjects()[1].dirty = true
          });
          canvas.requestRenderAll()
          setHighLightColor(null);
        })
      })
      canvas.on('mouse:out', () => {
        if (lastHoveredGroup) {
          lastHoveredGroup.forEach((targetGroup) => {
            targetGroup.getObjects()[1].set('visible', false);
            targetGroup.getObjects()[1].dirty = true
          });
          canvas.requestRenderAll()
          setHighLightColor(null);
        }
      });
    }

  }, [selectedTool, selectedColor, iEnvelopeGrid]);

  useEffect(() => {
    if (canvas && iEnvelopeGrid && iParaventGrid && iImageGroup && iTextGroup) {
      iEnvelopeGrid.flat().forEach((rectGroup) => {
        rectGroup.set('evented', ["Panel", "Row", "Gore", "SpiralUp", "Spiral", "Swap", "Envelope"].includes(selectedTool) ? true : false)
        rectGroup.set('visible', "Paravent" !== selectedTool)
      });
      iParaventGrid.set('visible', selectedTool === "Paravent")
      iParaventLogo?.set('visible', selectedTool === "Paravent")
      iParaventLogo?.set('evented', selectedTool === "Paravent")
      iImageGroup.forEach((image) => {
        image.set({
          selectable: selectedTool === "Graphic",
          'evented': selectedTool === "Graphic",
          visible: ["Graphic", "Text"].includes(selectedTool)
        })
      });
      iTextGroup.forEach((text) => {
        text.set({
          selectable: selectedTool === "Text",
          'evented': selectedTool === "Text",
          visible: ["Graphic", "Text"].includes(selectedTool)
        })
        text.bringToFront();
      });

      canvas.discardActiveObject()
      canvas.requestRenderAll();
    }
  }, [selectedTool])

  useEffect(() => {
    if (canvas && selectedTool === "Nomex") {
      const changes = [];
      iEnvelopeGrid.slice(0, 1).forEach((row) => {
        row.forEach((targetGroup) => {
          if (targetGroup) {
            const { row, col } = getCoordinatesFromId(targetGroup.id);
            if (canvasState.envelopeGrid[row][col] !== selectedNomexColor.hex) {
              const targetObject = targetGroup.getObjects()[0]
              changes.push({ path: 'envelopeGrid', row: row, col: col, old: targetObject.fill, new: selectedNomexColor.hex })
              targetGroup.getObjects()[0].set('fill', selectedNomexColor.hex);
              targetGroup.getObjects()[0].dirty = true
            }
          }
        });
      });
      setHistory((prevHistory) => {
        const newHistory = [...prevHistory, changes];
        return newHistory.slice(newHistory.length - historyLimit);
      });
      setRedoStack([]);
      setCanvasState((prevCanvasState) => {
        changes.forEach((change) => {
          prevCanvasState.envelopeGrid[change.row][change.col] = change.new;
        })
        return {
          ...prevCanvasState
        }
      })
      canvas.requestRenderAll()
    }
  }, [selectedNomexColor])


  useEffect(() => {
    if (canvas && svgContent && defaultColor && defaultNomexColor) {
      clearCanvas();

      let maxRow = 0;
      let maxCol = 0;

      const tEnvelopePanels = [];
      const tCanvasState = canvasState ? canvasState : {
        envelopeGrid: [],
        images: [],
        texts: [],
        paraventLogo: null
      }
      const panelsIDs = []

      svgContent.children.forEach((child) => {
        panelsIDs.push(child.properties.id);
        const properties = child.properties;
        const coords = getCoordinatesFromId(properties.id);
        if (coords) {
          maxRow = Math.max(maxRow, coords.row);
          maxCol = Math.max(maxCol, coords.col);
        }

        const panel = new fabric.Rect({
          id: properties.id,
          width: properties.width,
          height: properties.height,
          fill: coords.row === 0 ? defaultNomexColor.hex : defaultColor.hex,
          strokeUniform: true,
          stroke: 'grey',
          strokeWidth: 0.5,
          evented: false
        });

        const overlay = new fabric.Rect({
          id: "gradient-" + properties.id,
          width: panel.width,
          height: panel.height,
          fill: createGradient(panel.width, panel.height),
          opacity: 0.2,
          evented: false,
          visible: false,
          objectCaching: true
        });

        const panelGroup = new fabric.Group([panel, overlay], {
          id: properties.id,
          left: properties.x || 0,
          top: properties.y || 0,
          evented: true,
          selectable: false,
          hoverCursor: "pointer",
        })
        tEnvelopePanels.push(panelGroup);
      })

      canvas.renderOnAddRemove = false;

      const tParaventGrid = new fabric.Circle({
        id: "paravent-grid",
        radius: svgDimensions.height / 2,
        fill: 'grey',
        selectable: false,
        evented: false,
        visible: false,
        left: svgDimensions.width / 2 - svgDimensions.height / 2,
        absolutePositioned: true,
        top: 0,
      })

      canvas.add(tParaventGrid);

      const tEnvelopeGrid = Array.from({ length: maxRow + 1 }, () => Array(maxCol + 1).fill(null));
      if (!canvasState) {
        tCanvasState.envelopeGrid = Array.from({ length: maxRow + 1 }, () => Array(maxCol + 1).fill(null));
      }
      tEnvelopePanels.forEach((panelGroup, index) => {
        const coords = getCoordinatesFromId(panelGroup.id);
        if (coords) {
          canvas.add(panelGroup);
          let fill = null;

          try {
            fill = tCanvasState.envelopeGrid[coords.row][coords.col]
          } catch (e) {
            console.log(e)
          }

          if (fill) {
            panelGroup.getObjects()[0].set('fill', fill)
          } else {
            panelGroup.getObjects()[0].set('fill', coords.row === 0 ? defaultNomexColor.hex : defaultColor.hex)
            try {
              tCanvasState.envelopeGrid[coords.row][coords.col] = panelGroup.getObjects()[0].fill;
            } catch (e) {
              console.log(e)
            }
          }
          tEnvelopeGrid[coords.row][coords.col] = panelGroup;

          if (coords.row === 0 || coords.row === maxRow) {
            panelGroup.getObjects().forEach(object => {
              object.set('stroke', null);
              object.set('strokeWidth', 0);
              object.set('strokeUniform', true);
            });
          }
        }
      });

      const clipPath = new fabric.Rect({
        left: 0,
        top: tEnvelopeGrid.at(-paraventSize - 1)[0]?.top || 0,
        height: svgDimensions.height - tEnvelopeGrid.at(-paraventSize - 1)[0]?.top,
        width: parseFloat(svgDimensions.width),
        absolutePositioned: true,
      });

      setEnvelopeDimension({
        width: clipPath.width,
        height: clipPath.height,
        top: clipPath.top,
        left: clipPath.left,
        paraventRadius: tParaventGrid.radius
      })

      setEnvelopeClipPath(clipPath);
      setIEnvelopeGrid(tEnvelopeGrid);
      setIParaventGrid(tParaventGrid);

      if (canvasState) {
        tCanvasState.images.forEach((image) => {
          addImage(image, false, false, false, clipPath);
        })
        tCanvasState.texts.forEach((text) => {
          addText(text, false);
        })
        if (tCanvasState.paraventLogo) {
          addImage(tCanvasState.paraventLogo, true, false, false, tParaventGrid);
        }
      }

      setCanvasState(tCanvasState);

      canvas.renderOnAddRemove = true;
      canvas.requestRenderAll();

      canvas.on('object:modified', (e) => {
        updateCanvasState(getAllImportantInformations(e.target))
      })

      canvas.on('font:loaded', () => {
        const activeObject = canvas.getActiveObject();
        if (activeObject && activeObject.type === 'i-text') {
          activeObject.dirty = true;
        }
        canvas.requestRenderAll();
      });

      canvas.on('object:scaling', (e) => {
        var scaledObject = e.target;
        if (scaledObject.flipX === true || scaledObject.flipY === true) {
          scaledObject.flipX = false;
          scaledObject.flipY = false
        }
      });

      return () => {
        canvas.off('object:modified', (e) => {
          updateCanvasState(getAllImportantInformations(e.target));
        });
      };
    }
  }, [canvas, svgContent, defaultColor, defaultNomexColor]);


  return (
    <canvas ref={canvasContainerRef} id="canvas" style={{}} />
  );
};

export default React.forwardRef(Canvas);