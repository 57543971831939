import React, { useState, useEffect, useRef } from 'react';
import ContextToolBar from '../ContextToolBar/ContextToolBar';
import ImageUploader from '../ImageUploader/ImageUpoader';
import Canvas from './Canvas';

function CanvasContainer({
  svgContent, selectedTool, selectedColor,
  setSelectedColor, selectedNomexColor, setSelectedNomexColor,
  showContextToolBar, setShowContextToolBar, setSelectedTool,
  canvasState, setCanvasState, setEnvelopeDimension,
  defaultColor, defaultNomexColor, history, setHistory,
  redoStack, setRedoStack, canvasRef, viewMode,
  scenes, selectedScene, setSelectedScene, selectedFabric, paraventSize
}) {
  const [svgDimensions, setSvgDimensions] = useState(null);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [highlightColor, setHighlightColor] = useState(null);
  const containerRef = useRef(null);

  const handleCloseModal = () => {
    setShowImageUploader(false);
  };

  useEffect(() => {
    const viewBoxValues = svgContent.properties.viewBox.split(' ');
    setSvgDimensions(
      {
        width: viewBoxValues[2],
        height: viewBoxValues[3]
      }
    )
  }, [])


  return (
    <div ref={containerRef}
      style={{
        width: 'fit-content',
        display: viewMode === "Envelope" ? 'none' : 'flex',
        height: 'fit-content',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      {svgDimensions && selectedTool && canvasRef && showContextToolBar && ["Split", "Grid"].includes(viewMode) &&
        <ContextToolBar
          selectedTool={selectedTool}
          selectedColor={selectedColor}
          setSelectedColor={setSelectedColor}
          selectedNomexColor={selectedNomexColor}
          setSelectedNomexColor={setSelectedNomexColor}
          selectedFabric={selectedFabric}
          viewMode={viewMode}
          highlightColor={highlightColor}
          canvasRef={canvasRef}
          onClose={() => {
            setShowContextToolBar(false);
          }}
          showModal={showImageUploader}
          setShowModal={setShowImageUploader}
        />}
      {<ImageUploader
        show={showImageUploader}
        onHide={handleCloseModal}
        canvasRef={canvasRef}
        selectedTool={selectedTool}
      />}
      {svgDimensions &&
        <Canvas id="canvas-container"
          svgContent={svgContent}
          svgDimensions={svgDimensions}
          selectedTool={selectedTool}
          setSelectedTool={setSelectedTool}
          selectedColor={selectedColor}
          selectedNomexColor={selectedNomexColor}
          canvasState={canvasState}
          setCanvasState={setCanvasState}
          setEnvelopeDimension={setEnvelopeDimension}
          defaultColor={defaultColor}
          defaultNomexColor={defaultNomexColor}
          history={history}
          setHistory={setHistory}
          redoStack={redoStack}
          setRedoStack={setRedoStack}
          containerRef={containerRef}
          viewMode={viewMode}
          paraventSize={paraventSize}
          setHighLightColor={setHighlightColor}
          ref={canvasRef}
          style={{ position: 'absolute', top: 0, left: 0, border: '1px solid black', paddingTop: '1px', paddingRight: '1px' }} />}
    </div>
  );
}

export default CanvasContainer;